import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NotifyService } from './notify.service';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private _auth: AuthService, private _router: Router, private _notify: NotifyService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = window.location.href;
    if (url.indexOf('filazero.net/#/app/p/sebraese/10') !== -1) {
      this._router.navigate(['/p/sebraese/services']);
    }

    const currentUser = JSON.parse(localStorage.getItem('authorizationData'));
                                             // refatorar
    if (currentUser && currentUser.token && !request.url.startsWith('https://viacep.com.br')) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${currentUser.token}`
          }
        });
    }

    return next.handle(request)
      .pipe(
        catchError((err: any): Observable<HttpEvent<any>> => {
          if (err.status === 401) {
            if(!request.url.includes("https://api.dev.filazero.net/api/providers/slug/")){
              this._auth.logOut();
              this._router.navigate(['/user/login']);
            }

            return next.handle(request);
          }
          return next.handle(request);
        })
      );
  }
}
